.device
    &__img
        width: 300px
        height: 300px
        object-fit: contain
    &__center
        &__text
            text-align: center
    &__star
        width: 240px
        height: 240px
        background: url('../assets/bigStar.png') no-repeat center center
        background-size: cover
        font-size: 60px
    &__card
        width: 300px
        height: 300px
        font-size: 32px
        border: 5px solid lightgray

@media (max-width: 991px)
    .device
        &__img
            width: 240px
            height: 240px
        &__center
            &__text
                margin-bottom: 0
                font-size: 25px
        &__star
            width: 180px
            height: 180px
            font-size: 50px
        &__card
            width: 240px
            height: 240px

@media (max-width: 767px)
    .device
        display: grid
        grid-template-columns: repeat(auto-fit, 240px)
        justify-content: space-around
        column-gap: 30px
        row-gap: 20px
        &__star
            width: 120px
            height: 120px
            font-size: 30px