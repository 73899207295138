.brand-bar
    display: flex
    &__card
        padding: 10px
        margin-top: 30px
        margin-left: 10px
        cursor: pointer
        &:hover
            background-color: lightgray

@media (max-width: 630px)
    .brand-bar
        display: grid
        grid-template-columns: repeat(auto-fit, 120px)
        column-gap: 10px
        &__card
            padding: 5px
            margin-top: 10px
